import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { withTheme } from '@material-ui/core'
import { SEOHeaders } from 'gatsby-components'

function SEO({ theme, ...props }) {
  // Gatsby's static queries must be a static string in a file in the gatsby app
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <SEOHeaders
      site={site}
      bodyAttributes={{
        dir: theme.direction,
      }}
      {...props}
    />
  )
}

const propTypes = Object.assign({}, SEOHeaders.propTypes)
delete propTypes.site
SEO.propTypes = propTypes

export default withTheme(SEO)
